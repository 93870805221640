
.imgArt1-container {
    /* border: 10px soild red;
    position: absolute;
    display: flex;
    right: 0;
    margin-right: 0; */
}

.imgArt1-img {
    position: absolute;
    /* margin-left: 60%; */
    left: 12%;
    top: 30%;
    border: 1px soild red;
    width: 140%;
    height: 140%;
  
}