/* auth.css */



.wrapper {
  position: relative; /* Ensure the container is positioned relative to the video */
  z-index: 1; /* Ensure it appears above the video */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh; 
  margin: -10px;
}

.video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensure the video covers the entire screen */
  z-index: -1; /* Place the video behind all other elements */
  border: none; /* Remove the border if not needed */
}

.container {
  margin-top: 10px;
  /* width: 80%; */
}

.logoContainer {
}
.headerLogo {
  color: #111111;
  background-color: #e8e8e8;
  height: 45px;
  width: 50px;
  padding-top: 3px;
  text-align: center;
  border-radius: 10px;
  margin-left: 40%;
  margin-right: 40%;
}

.headerContainer {
  text-align: center;
  margin-bottom: 50px;
}
.headerh1 {
  font-size: 22px;
}


.forgot-btn {
  background-color: #11111100;
}

.ButtonContainer {
  position: relative;
  display: inline-block;
  justify-content: center;
  align-items: center;
}


.buttonSignup {
  width: 100%;
  padding: 20px 0 20px 0;
  margin-bottom: 20px;
  border-radius: 10px;
  border: 0px;
  background-color: #fff;
  color: #111111;
}
.buttonLogin {
  width: 100%;
  padding: 20px 0 20px 0;
  border-radius: 10px;
  border: 1px solid #fff;
  background-color: #1111115f;
  color: #fff;
}













.auth-wrapper {
    max-width: 400px;
    height: 90vh;
    margin: 0 auto;
    padding: 20px 40px 0 40px;

  }

  
  .login-message {
    margin-top: 0;
    color: #212121;
    font-size: 36px;
    margin-bottom: 25px;
    font-weight: 600;
  }
  
  .auth-form {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  
  .auth-form-label {
    display: flex;
    align-items: center;
    margin-bottom: 25px;
    border: none;
    border-bottom: 1px solid #ffffff;
  }
  
  .auth-form-icon {
    width: 20px;
    height: 20px;
    margin-right: 10px;
    padding-bottom: 10px;
  }
  
  .auth-form-input {
    width: 100%;
    padding: 10px;
    padding-bottom: 20px;
    border: none;
    box-sizing: border-box;
    background-color: rgba(0, 0, 0, 0);
    font-size: 16px;
    color: #ffffff;
  }

  *::placeholder {
    color: #ffffff;
  }

  .auth-form-input:focus{
    outline: none;
    color: #ffffff;
  }
  
  .auth-form-button-group {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
  }
  
  .button {
    border: 1px solid #212121;
    border-radius: 5px;
    padding: 15px;
    margin-bottom: 10px;
    background-color: #212121;
    color: #ffffff;
    cursor: pointer;
  }
  
  .button-secondary {
    border: 1px solid #ffffff;
    border-radius: 5px;
    padding: 15px;
    margin-bottom: 10px;
    background-color: white;
    color: #212121;
    cursor: pointer;
    margin-top: 10px;
  }
  
  .signup-button-group {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
  }
  
  .button-signup {
    border: 1px solid #ffffff;
    border-radius: 5px;
    padding: 20px;
    background-color:  #111111;
    color: #ffffff;
    cursor: pointer;
  }

  .button-signup-2 {
    border: 0px solid #ffffff;
    border-radius: 5px;
    padding: 20px;
    background-color: #ffffff;
    color: black;
    font-size: 14px;
    cursor: pointer;
    margin-bottom: 20px;
  }
  
  .button-signup:hover {
    background-color: #e8e8e8;
    color: black;
  }

  .button-signup-2:hover {
    background-color: white;
    color: black;
  }


  /* Responsive Styles */
  @media (max-width: 768px) {
    .auth-wrapper {
      max-width: 100%;
    }
    
    .auth-form-input {
      font-size: 16px;
    }
  
    .button,
    .button-secondary,
    .button-signup {
      font-size: 14px;
    }
  }
  
  .auth-form-button-group{
    display: flex;
    flex-direction: column;
  }
  .signup-p{
    color: #ffffff;
    font-size: 15px;
    text-align: center; 
  }

  .logoimg {
    width: 80%;
    margin-left: 10%;
    margin-top: 80px;
    margin-bottom: 60px;
  }

  .auth-h1 {
    font-size: 40px;
    margin: 60px 0 40px 0;
    /* text-align: center; */
  }

  .auth-h2, .auth-h3 {
    font-size: 25px;
    margin-bottom: -15px;
    color: #fff;
    /* text-align: center; */
  }
  .auth-h3 {
    margin-bottom: 50px;
  }

  .auth-h2-ref {
    color: #808080;
    margin-bottom: 25px;
    margin-top: -15px;
    font-weight: 300;
    font-size: 15px;
    border: 1px solid #212121;
    background-color: #21212100;
    width: fit-content;
    padding: 8px 10px;
    border-radius: 10px;
  }



  .forgot-btn {
    border: 0px;
    background-color: #11111100;
    color: #808080;
    width: auto;
    padding: 10px;
    display: flex;
    text-align: right;
    margin-bottom: 10px;
  }

  .auth-error {
    text-align: center;
    margin: -30px 0 15px 0 ;
    color: #ff4e4e;
    font-weight: 300;
    font-size: 15px;
    border: 1px solid #ff4e4e11;
    /* background-color: #e4e6e8; */
    /* width: fit-content; */
    padding: 8px 10px;
    border-radius: 10px;
  }

  .auth-msg {
    text-align: center;
    margin: 10px 0 15px 0 ;
    color: #4e5aff;
    font-weight: 300;
    font-size: 15px;
    border: 1px solid #6e4eff11;
    /* background-color: #e4e6e8; */
    /* width: fit-content; */
    padding: 8px 10px;
    border-radius: 10px;
  }
  


  .-auth-h2, .-auth-h3 {
    font-size: 25px;
    margin-bottom: -15px;
    color: #ffffff;
  }
  .-auth-h3 {
    margin-bottom: 50px;
  }
  .-link {
    border-bottom: 2px solid #fff;
  }

  .-personal {
    font-style: italic;
    font-weight: 200;
  }


  /* .auth-color-1 {
    background-color: #051917 !important;
  }

  .auth-color-2 {

  }

  .auth-color-3 {
    color: #0B835E !important;
  } */

  .auth-h2-italic {
    font-style: italic !important;
    font-weight: 300 !important;
  }