/* 
.navbar-wrapper {
  position: fixed;
  justify-content: center;
  align-items: center;
  bottom: 0;
  width: 100vw;
  z-index: 9999;
  margin-bottom: 5px;
}

.navbar-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.auth-nav-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 38%;
  margin-right: 40%;
  background: #111111cb;
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(50px);
  border: 1px solid rgba(0, 0, 0, 0.3);
  padding: 10px;
  margin-bottom: 50px;
}

.q-text{
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 10px;
} */
/* 
@media screen and (max-device-width: 580px) { */

  .navbar-wrapper {
    position: fixed;
    justify-content: center;
    align-items: center;
    bottom: 0;
    width: 100vw;
    z-index: 999999;
    /* border: 1px solid red; */
  }

  .navbar-wrapper {
    position: fixed;
    justify-content: center;
    align-items: center;
    bottom: -5px;
    width: 100vw;
    z-index: 999999;
  }

  .navbar-container {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-left: -15px;

  }
  
  .auth-nav-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background: #101114e1;
    /* box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1); */
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(50px);
    padding: 0px 5px 30px 5px;


  }
  
  .q-text{
    font-size: 13px;
    font-weight: 600;
  }

/* } */

.CTA2 {
  text-decoration: none;
  background-color: #fff;
  border-radius: 16px;
  box-shadow: 0 4px 30px rgb(0, 0, 0);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(50px);
}

.CTA-text {
  margin: 0;
  color: black;
}


.auth-nav-wrapper-2{
  width: fit-content;
}

.button2 {
  border: 1px solid #ffffff;
  border-radius: 5px;
  padding: 15px;
  width: 100%;
  text-align: center;
  font-weight: 600;
  margin: 5px;
}


.navButton {
  border-radius: 5px;
  padding: 15px;
  display: block;
  justify-content: center;
  align-items: center;
  width: fit-content;
  margin: 0px 10px 0px 10px;
}

.active-nav {
  padding-bottom: 8px;
  margin-bottom: -8px;
  border-bottom: 1px solid rgb(255, 255, 255);
}

.navText {
  display: flex;
  display: none;
  font-size: 10px;
  margin: 4px 0 0 0;
}

/* 
.button-icon-x {
  position: fixed;
  top: 0;
  z-index: 9999;
  border: 1px solid red;
} */


/* .button-icon {
  padding: 15px;
} */

.auth-nav-wrapper-2 {
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 55%;
  max-width: 300px;
  margin: 0 auto;
  margin-bottom: 20px;
  padding: 10px 40px 10px 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background: rgba(0, 0, 0, 0.6);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(50px);
  border: 1px solid rgba(0, 0, 0, 0.3);
}




.links-container {
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  padding: 10px 0 0 0;
  margin: 0 auto;
  display: flex;
  z-index: 1000;
  background: #101114e1;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(50px);
}

.link-container {
  display: flex;
  align-items: center;
  border-radius: 10px;
  padding: 1px 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: space-between;
  flex: 1;
    border: 1px solid #fff;
  margin-bottom: 92px;
}

.link-container:nth-child(1) {
  /* border: 1px solid #83F199;
  background-color: #83f199;
  color: #111111 !important; */
  margin-left: 30px;
}

.link-container:nth-child(2) {
  margin-right: 30px;
}


.linkr {
  margin-right: 10px;
}

.link-container p {
  /* color: #808080; */
}

.link {
    
}