.explore-page {
    padding-top: 10px;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    justify-content: center;
    /* margin: 0 20px; */
    position: relative;
    padding-bottom: 120px;
}

.loading {
    text-align: center;
}

.explore-h1 {
    margin-top: 10px;
    margin-bottom: 0;
    font-size: 25px;
    margin: 10px 30px;
}
.explore-h22 {
    margin-top: 10px;
    margin-bottom: 0;
    font-size: 18px;
    margin: 10px 0 10px 30px;
    margin-right: auto;
    color: #545764;
}



.blank {
    font-size: 12px;
    color: #545764;
    padding: 2px; 
    border-radius: 5px;
    margin: 2px 5px 10px 5px;
    opacity: 0;
  }

  .explore-nav-2 {
    display: flex;
  }


.explore-tutorial-container {
    background-color: #18191D;
    margin-left: auto;
    margin-right: auto;
    margin-top: 40%;
    margin-bottom: 10%;
    padding: 10px 20px;
    border-radius: 15px;
}

.explore-h2 {
    color: #ffffff;
    font-weight: 600;
    text-align: center;
}

.explore-tutorial {
    display: flex;
    margin-bottom: 10px;
}

.explore-tutorial-text {
    color: #545764;
}

  
.active {
    background-color: #545454;
}

.explore-profile-button {
    position: absolute;
    margin: 0;
    padding: 5px 8px;
    margin-top: -55px;
    border-radius: 10px;
    opacity: 0.8;
}

.explore-pumpcard-btns-container {
    position: absolute;
    margin-top: -50px;
    display: flex;
    align-items: center;
    list-style: none;
}

.explore-username {
    /* position: absolute;
    margin: 0;
    padding: 5px 8px;
    margin-top: -40px;
    margin-left: 5px;
    border-radius: 10px;
    opacity: 0.8;
    font-size: 10px;
    background-color: #111111;
    border-radius: 50px;
    opacity: 0.6; */
    background-color: #101114e0;
    border-radius: 50px;
    padding: 5px 8px;
    font-size: 10px;
    margin-left: 5px;
    opacity: 0.8;

}

.like-toggle {
    /* position: absolute;
    margin: 0;
    padding: 3px 7.5px 3px 7px;
    margin-top: -75px;
    height: 20px;
    margin-left: 5px;
    border-radius: 10px;
    opacity: 0.8;
    font-size: 10px;
    background-color: #111111;
    border-radius: 50px;
    opacity: 0.6;
    display: flex;
    align-items: center; */
}

.like-toggle-open {
    /* position: absolute;
    margin: 0;
    margin-top: -70px;
    margin-left: 5px;
    list-style: none;
    display: flex;
    padding: 2px 5px;
    background-color: #1111118b;
    border-radius: 50px; */
}

.like-toggle-closed {
    /* position: absolute;
    margin: 0;
    margin-top: -70px;
    margin-left: 5px;
    list-style: none;
    padding: 2px 5px;
    background-color: #1111118b;
    border-radius: 50px; */
}

.no-post-container {
    position: relative;
    width: 100%;
    height: 50px;
    border-radius: 5px; 
    overflow: hidden; 
    margin: 0;
    margin-bottom: 12px;
    justify-content: center;
    align-items: center;
    /* display: flex; */
    text-align: center;
    background-color: #18191D;
}

.no-post-text {
    color: #545764;
    font-weight: 600;
    font-size: 15px;
}



.leaderboard-2{
display: flex;
height: auto;
overflow-x: scroll;
max-width: 100%;
padding: 10px 0 ;
margin:  0 25px;
margin-bottom: 10px;
}

.streak{
display: flex;
flex-direction: column;
margin-right: 10px;
align-items: center;
justify-content: center;
position: relative;
}

.leaderboard-streak-2{
   font-size: 16px;
   font-weight: bold;
   margin-right: 3px;

}
.leaderboard-streak-3{
    font-size: 16px;
    font-weight: bold;
     
 }


.leaderboard-name-wrapper{
    display: flex;
}
.streak-wrapper{
    width: 60px;
    height: 60px;
    border-radius: 100px;
    background-color: #18191D;
    display: flex;
align-items: center;
justify-content: center;
}

.leaderboard-place-2{
    margin: 0 0 5px 0;
    padding: 0;
    font-size: 12px;
    color: #545764;
    /* position: absolute;
    top:0;
    left: 0; */
}

.leaderboard-name-2{
    margin: 0;
    padding: 0;
    color: #545764;
    font-size: 10px;
    text-decoration: none;
}

.leaderboard-name-wrapper-2{
    display: flex;
    flex-direction: column;
    text-align: center;
    margin: 0;
    padding: 0;
    padding-top: 10px;
}

.leaderboard-2::-webkit-scrollbar {
    display: none;
  }
  
  /* Hide scrollbar for IE, Edge and Firefox */
  .leaderboard-2 {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }



  .gold-bg {
    color: #fff;
    border: gold 1px solid;
  }
  
  .silver-bg {
   color: #fff;
    border: silver 1px solid;
  }
  
  .bronze-bg {
    color: #fff;
    border: #cd7f32 1px solid;
  }

  .grey-bg {
    color: #545764;
  }


  .oneHundred {
    color: linear-gradient(to right, red, orange, yellow) !important;
    animation: rotateColors 10s linear infinite;
    
  }
  
  .show-container {
    display: flex;
    align-items: center;
  }
  
  .show-text {
    color: #5457648d;
    margin-right: 5px;
    font-size: 13px;
    transition: opacity 0.2s ease-out; /* Smooth fade-in and fade-out */
    opacity: 1; /* Initially fully visible */
  }
  
  .show-text-hidden {
    opacity: 0; /* Fully hidden */
  }

  
  
  .visible-button {
    border-radius: 5px;
    background-color: #18191D;
    padding: 3px 5px 0px 5px;
    align-self: center;
    margin-right: 20px;
  }
  