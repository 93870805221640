.image-gallery-left-nav, .image-gallery-right-nav {
    position: absolute;
    border: 0px solid rgb(255, 0, 0);
    height: 80%;
    width: 35%;
    z-index: 1;
    background-color: #f5f5f500;
    cursor: pointer;
  }

  .pump-card {
    position: relative;
    width: 100%;
    max-height: 600px;  
    border-radius: 5px; 
    overflow: hidden; 
    margin: 0;
    margin-bottom: 12px;

    justify-content: center;
    align-items: center;
    display: flex;
  }

  .selected {
    width: 200%;
    margin-left: auto;

  }
  
  
  .pump-card img {
    display: block;
    width: 100%;
    height: auto;
    
    justify-content: center;
    align-items: center;
    display: flex;

  }

  

  .options-menu-trigger {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 20px;
    height: 10px;
    padding: 10px 10px 20px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    margin: 5px;
    z-index: 1;
    cursor: pointer;

  }

  .options-menu-trigger.open {
      background-color: #101114af;
  }
  
  .options-menu {
    position: absolute;
    opacity: 0;
    width: 50px;
    transition: width 0.3s ease-in-out, opacity 0.3s  ease-in-out;
}

.options-menu.open {
  width: 105px;
  opacity: 1;
  background: #101114af;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(10px);
  height: 10px;
  padding: 10px 10px 20px 10px;
  border-radius: 50px;
  overflow: hidden;
  bottom: 0;
  right: 0;
  margin: 5px;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

  
  .options {
    display: flex;
  }

  .option1 {
    align-self: center;
    margin-top: 10px;
     margin-left: 30px;
     color: #fff;
   }
  
  .option {
   align-self: center;
   margin-top: 10px;
    margin-left: 20px;
    color: #fff;
  }


  @media screen and (max-device-width: 580px) {
    .image-gallery-left-nav, .image-gallery-right-nav {
        display: none;
      }
}

.image-count {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 20px;
  height: 1px;
  background-color: #101114e0;
  padding: 9px 2px 9px 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 50px;
  margin: 5px;
  z-index: 1;
  font-size: 10px !important;
  font-weight: 100;
  opacity: 0.8;
}

.dots {
  color: #fff;
}

.loading-message {
  opacity: 0;
  transition: opacity 2s ease; 
  background-color: #18191D;
  color: #18181800;
  height: 99%;
  border-radius: 5px;
  margin: 0;
  position: absolute;
  justify-content: center;
  align-items: center;
  width: 99%;
  text-align: center;
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0.01), transparent);
  background-size: 200% 100%;
  animation: shine 2.5s linear infinite;
}

.loading-message.visible {
  opacity: 1;
}


@keyframes shine {
  from {
    background-position: -100% 200%; /* Start from bottom left */
  }
  to {
    background-position: 200% -200%; /* End at top right */
  }
}



/* :not(:root):fullscreen {
  padding-top: 40% !important;

  overflow:  hidden !important;
  border: 1px solid red !important;
  position: fixed !important;
  box-sizing: border-box !important;
  object-fit: cover !important;
  border-radius: 20px;
  height: 100px !important;
  width: 100px !important;
  max-height: 100px !important;
  max-width: 100px !important;
  flex: none !important;
} */

/* :not(:root):fullscreen img {
  border-radius: 10px;
  width: 80vw;
  margin-top: 20vh;
  margin-left: auto;
  margin-right: auto;
} */

/* :not(:root):fullscreen.fullscreen-modal {
  overflow:  hidden !important;
  border: 1px solid red !important;
  position: fixed !important;
  box-sizing: border-box !important;
  object-fit: cover !important;
  border-radius: 20px;
  height: 100px !important;
  width: 100px !important;
  max-height: 100px !important;
  max-width: 100px !important;
  flex: none !important;
} */

/* .image-gallery-fullscreen .image-gallery-slide img {
  max-width: 100px;
  max-height: 100px;
  width: auto;
  height: auto;
  object-fit: cover;
} */




