.hero {
    position: absolute;
    top: 0;
    color: #F4F3EF;
}
.hero img {
    /* justify-content: center;
    align-items: center;
    align-self: center; */
    width: auto;
    height: 100%;
    margin-top: 0;
    margin-left: -100%;
}
.hero {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.2);
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin-top: 15%;
    margin-left: 10%;
}

.hero h1 { 
    font-size: 4rem;
    font-weight: 400;
}

.h {
    margin-bottom: -40px;
}

.bold {
    font-family: "semiBold";
}

.hero-btn {
    padding: 15px 40px;
    background-color: #F4F3EF;
    border-radius: 10px;
    color: #142128;
    font-size: 16px;
    border: none;
    cursor: pointer;
}

@media (max-width: 768px) {
    .hero h1 { 
        font-size: 1.5rem;
        font-weight: 400;
        text-align: center;
    }
    .h {
        margin-bottom: -15px;
    }
    .overlay {
        width: 100%;
        margin-top: 55%;
        margin-left: 0;
    }
}


