.create-container {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9999999;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #101114;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(50px);
  padding-bottom: 10px;
}

.image-previews {
  width: 180px;  
  margin: -10px auto 45vh auto;
  border-radius: 5px;
  overflow: scroll;

  max-height: 240px;  
  border-radius: 5px; 
  overflow: hidden; 
  justify-content: center;
  align-items: center;
  display: flex;
}

.create-form {
  display: block;
  height: auto;
  position: fixed;
  margin-left: auto;
  margin-right: auto;
  bottom: 35px;
  width: 60%;
  margin-top: 30px;
}

.create-text {
  text-align: center;
  color: #545764;
}
  
.buttons-wrapper {
  justify-content: center;
  text-align: center;
}

  .custom-file-label {
    margin-top: 10px;
    font-size: 14px;
    cursor: pointer;
    border: 1px solid #fff;
    background-color: #101114;
    color: #fff;
    border-radius: 5px;
    padding: 15px;
    display: flex;
    justify-content: center;
    text-align: center;
    cursor: pointer;
  }

  .visually-hidden {
    position: absolute !important;
    clip: rect(1px, 1px, 1px, 1px); /* Clip the element to hide it visually */
    width: 1px !important;
    height: 1px !important;
    overflow: hidden;
  }

  .create-pump-button {
    border: 1px solid #fff;
    background: #fff;
    border-radius: 5px;
    padding: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    color: #101114;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    cursor: pointer;
    margin-top: 40px;
}
.create-pump-button:hover {
  border: 1px solid #fff;
  background: #fff;
}


.close-wrapper{
  margin-top: 50px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.x-button {
  /* border: 1px solid #808080; */
  border-radius: 5px;
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  color: #101114;
  margin: 5px;
}

.x-button:hover {
  border: 1px solid #545764;
}

  .loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #101114cd;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(50px);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100000;
  }

  .loading-container {
    display: block;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 14px;
    color: #545764;
  }

  .error {
    text-align: center;
    color: #d85454;
    /* margin-left: 80px;
    margin-right: 40px; */
  }

