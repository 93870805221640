:root {
  --toastify-color-dark: #101114  !important;
  --toastify-toast-max-width: 70px  !important;
  --toastify-color-progress-dark: #545764 !important;
  --toastify-text-color-dark: #fff  !important;
}

@font-face {
  font-family: "Bold";   /*Can be any text*/
  src: local("ClashDisplay-Bold"),
    url("/src//fonts/ClashDisplay-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "semiBold";   /*Can be any text*/
  src: local("ClashDisplay-Semibold"),
    url("/src//fonts/ClashDisplay-Semibold.ttf") format("truetype");
}

.Toastify__toast {
  margin: 52px !important;
  margin-bottom: 100px !important;
  background: #101114b6 !important;
  border-radius: 16px !important;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1) !important;
  backdrop-filter: blur(5px) !important;
  -webkit-backdrop-filter: blur(50px) !important;
  border: 1px solid #101114b6 !important;
}
.Toastify__toast-body {
  text-align: center;
}
.Toastify__close-button {
  display: none;
}

body{
  background-color: #101114 !important;
      /* background-color: #051917 !important; */
  color: white;
  overflow-x: hidden;

  
}

.hide-overflow {
  overflow: hidden;
  scroll-behavior: none;
}

.color-2 {
  color: #fff !important;
  /* color: #da4c4c !important; */
}

/* .color-3 {
  color: #0B835E !important;
} */

/* @keyframes subtleColorBlend {
  0% {
    background-position: 0% 0%;
  }
  100% {
    background-position: 100% 0%;
  }
} */

/* .color-2-bkg {
  background: linear-gradient(to right, #83f199, #4f9d5f); 
  background-size: 200% auto; 
  animation: subtleColorBlend 5s infinite alternate; 
  border: 0 !important;
} */

/* .color-1-bkg {
  background-color: #051917da !important;
} */

.color-2-bkg {
  background-color: #fff !important;
  /* background-color: #da4c4c !important; */
  border: 0 !important;
}

/* .color-3-bkg {
  background-color: #0B835E !important;
  border: 0 !important;
}
.color-3-bkg-active {
  background-color: #085a41 !important;
} */

* {
  font-family: Arial, Helvetica, sans-serif;
}
.CTA {
  background-color: #fff;
  color: black;
  text-decoration: none;
}
.CTA:hover {
  background-color: #fff;
}

.stretch {
  width: 90%;
  animation: bounce 1s infinite ease-in-out;
  font-weight: 900;
}

@keyframes bounce {
  0%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
}


.image-gallery-bullets {
  bottom: 5px;
} 
