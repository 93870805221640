.tutorial-username-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 9999999;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    background: #101114;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(50px);
}

.tutorial-box {
    background-color: #101114;
    position: absolute;
    width: 100vw;
    height: 100vh;
}

.create-username-wrapper {
    width: 70%;
    margin: auto auto;
    margin-top: 15vh;
    /* height: 80%;     */
}   

.create-username-h1 {
    font-size: 35px;
    margin-bottom: 50px;
    text-align: left;
}

.create-username-wrapper p {
    text-align: left;
    font-weight: 100;
    /* font-size: 16px; */
}

.username-message-container {
    background-color: #212121;
    /* width: 60%; */
    /* margin-top: 32vh; */
    margin-bottom: 10%;
    padding: 5px 20px;
    border-radius: 15px;
}

.username-message1 {
    text-align: left;
    margin-left: 10px;
}
.username-message2 {
    text-align: left;
    margin-right: 0;
    margin-left: 10px;
    color: #808080;
}

.create-username-form{

}

.create-username-input{
    text-align: left;
    background-color:rgba(240, 248, 255, 0);

    padding: 20px 0;

    width: calc(100% - 3px);
    font-size: 16px;
    color: #fff;
    padding-left: 3px;
    border: none;
    outline: none;
}

.username-error-message { 
    margin: 40px 0;
    color: rgb(255, 78, 78);
}


.basics-h5 {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    bottom: 0;
    margin-bottom: 45%;
    font-size: 14px;
}


.app-img-container {
    margin-top: 20px;
    border-radius: 15px;
    overflow: hidden;
}





.arrow {
    position: absolute;
    bottom: 0;
    margin-bottom: 25%;
    width: 70%;
    display: block;
    /* border: 1px solid red; */
}


.arrow-button {
    background-color: #fff;
    color: #101114;
    padding: 20px;
    
    /* width: 100%; */
    border-radius: 10px;
    text-align: center;
}

.arrow-button2 {
    background-color: #101114d3;
    background-color: #101114;
    /* border: 1px solid #808080; */
    color: #808080;
    padding: 5px;
    border-radius: 10px;

    position: absolute;

    bottom: 0;
    margin-bottom: -26%;
    left: 50%;
    transform: translateX(-50%);
    
    text-align: center;
    /* width: 100%; */
    
}
.back-arrow {
    margin-top: 5px;
}
  