

.user-name-2{
    align-items: center;
    margin: 0 30px;
}

.user-gallery-h1 {
    font-size: 25px;
    margin-right: 10px;
}


.user-icon-gal{
    border: 0px solid white;
    border-radius: 100px;
    width: 0px;
    padding: 5px;
}

.stats-container {
    /* border: 1px solid red; */
    display: flex;
}

.gallery-container {
    background-color: #18191D;
    text-align: center !important;
    margin-right: 10px;
    padding: 0 8px 0 8px;
    border-radius: 10px;
}

.user-gallery-streak {
    font-size: 12px;
}

.firstPlace {
    /* border: 1px solid linear-gradient(to right, gold, orange, yellow);
    animation: rotateColors 10s linear infinite;; */
    /* border: 1px solid red; */
    /* background: linear-gradient(to right, red, orange, yellow);
    animation: rotateColors 10s linear infinite; */
}

.firstPlaceContainer { 
     border: 1px solid gold;
     
    /* background: linear-gradient(to right, red, orange, yellow);
    animation: rotateColors 10s linear infinite; */
    
}