/* .privacy-container {
    margin-left: 15%;
    margin-right: 15%;
}

.privacy-h1 {
    font-weight: 900;

}

.privacy-h2 {
    margin-bottom: 10px;
}

.privacy-h3 {
    margin: 0;
    font-weight: 200 !important;
} */


.container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
}
.modal-name {
    font-size: 38px;
    font-weight: bold;
    padding: 10px;
    margin-left: -3px;
    margin-bottom: 30px;
}
.modal-header {
    font-size: 24px;
    font-weight: bold;
    padding: 10px;
}
.modal-date {
    font-size: 12px;
    padding-left: 10px;
    padding-bottom: 10px;
}
.modal-h2 {
    font-size: 18px;
    font-weight: bold;
    padding: 10px;
}
.modal-text {
    font-size: 15px;
    padding: 10px;
}