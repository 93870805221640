.header {
    position: sticky;
    top: 0px;
    height: 4rem;
    transition-property: all; 
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 500ms;
    z-index: 1;
    /* margin-left: 10%;
    margin-right: 10%; */
    /* background-color: #0d0d0d2d; */
  }

  .space {
    margin-left: 10%;
    margin-right: 10%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  
  .header.down {
    top: -6rem;
  }

  .hide {
    transform: translateY(-100%);
  }
  
  /* Show class for when scrolling up */
  .show {
    transform: translateY(0);
  }
  


nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    
}

.logo {
    margin-top: 10px;
}

.menu-mid a {
    margin-left: 50px;
    color: #F4F3EF;
    text-decoration: none;
    padding: 10px;
}

.menu-right a {
    color: #F4F3EF;
    text-decoration: none;
    padding: 10px;

    /* margin-right: 30px; */
}

.menu-right {
  justify-content: flex-end;
  align-self: flex-end;
  /* border: 1px solid blue; */
  align-items: flex-end;
  justify-self: flex-end;
  justify-items: end;
  right: 0;
  margin-left: 30%;
}


/* Default styles for desktop */
.menu {
  display: flex;
  width: 80%;
  justify-content: space-between;
}

.hamburger-menu {
  display: none;
  padding: 15px;
  z-index: 5;
}

/* Media query for mobile screens */
@media (max-width: 768px) {
  .menu {
      display: none; /* Hide the menu by default */
      flex-direction: column; /* Stack links vertically */
      position: absolute;
      top: 0;
      /* height: 100vh; */
      right: 0;
      background-color: rgb(24, 24, 24); /* Customize background color */
      width: 100%;
      padding: 10px;
      z-index: 2;
  }

  .menu.open {
      display: block; /* Show the menu when hamburger is clicked */
      height: 100vh;
  }

  .menu-mid.open-mid {
    margin-left: -30px;
    margin-top: 50px;
  }

  .menu-mid.open-mid a {
    display: block;
    text-align: center;
    font-size: 18px;
    margin-bottom: 20px;
  }
  .menu-right.open-right {
    margin-left: 20px;
  }

  .menu-right.open-right a {
    text-align: center;
    display: block;
    font-size: 18px;
  }


  .hamburger-menu {
      display: block; /* Show the hamburger button */
      cursor: pointer;
  }

  .logo {
      margin-right: auto; /* Push the logo to the left */
  }
}
