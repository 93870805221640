/* profile.css */

/* General Profile Wrapper */
.profile-wrapper {
    padding-top: 10px;
    display: flex;
    flex-direction: column;

    justify-content: center;

    position: relative;
    padding-bottom: 120px;
    overflow-y: auto;
  }

/* .profile-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 800px;
    
} */

.top-profile-nav {
  margin: 0 30px;
}
  
  .profile-h1 {
    margin-top: 10px;
    color: #ffffff;
    margin-bottom: 0;
    font-size: 25px;
  }

  .profile-h2 {
    margin-bottom: 5px;
    font-size: 16px;

    color: #545764;
  }

  .profile-stats {
    display: flex;
    margin-top: 10px;
  }

  .userData-container {
    /* border: 1px solid #373941; */
    background-color: #18191D;
    text-align: center !important;
    margin-right: 10px;
    padding: 0 8px 0 8px;
    border-radius: 10px;
  
  }

  .userData-container-2 {
    /* border: 1px solid #18191D; */
    /* background-color: #18191D; */
    text-align: center !important;
    margin-right: 10px;
    padding: 0 8px 0 8px;
    border-radius: 10px;
  
  }

  .userData {
  text-align: center !important;
  font-size: 12px;
  color: #545764;

  }

  .placement {
    color: #545764;
    font-weight: bold;
    color: #fff;
  }
  
  .streak-count {
    color: #fff;
    font-weight: bold;
  }

  .views-count {

  }

  .referral-count {

  }



  /* View Toggle Buttons */
  .view-toggle-wrapper {
    /* Add your view toggle wrapper styles here */
    display: flex;
    justify-content: center;
    align-items: center;
    

  }
  
  .masonry-grid {
    display: flex;
    flex-wrap: wrap;
    margin: 0 20px;
  }
  
  .masonry-grid-column {
    flex: 1;
    padding: 0 6px; /* Adjust as needed based on your design */
    box-sizing: border-box;
  }

  .timeline {
    font-size: 12px;
    margin-left: 30px;
    /* opacity: 0.5; */
    color: #545764;
  }
  
  
  /* Responsive Styles */
  @media (max-width: 768px) {
    /* Add your responsive styles for screens up to 768px wide */
  }
  
  @media (max-width: 480px) {
    /* Add your responsive styles for screens up to 480px wide */
  }
  

  .button {
    border-radius: 5px;
    padding: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    background-color: #212121;
    color: white;
    margin: 5px;
  }
  

  .button .button-icon, .button2 {
    color: white;
  }
  
  .button:hover {
    color: black;
  }
  

  .button:hover .button-icon {
    color: black;
  }

  .button-active {
    background-color: #545454 !important;
    color: black !important;
  }
  

.empty-message{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.arnold{
  width: 150px;
}
 .arnold-message{
  font-weight: 800;
  color: #545764;
 }

 .tutorial-message {
    font-weight: 800;
    color: #fff;
 }

 .tip-wrapper {
  width: auto;
  margin: 25px;
 }

 .tip-container {
    background-color: #18191D;

    border-radius: 15px;
    padding: 5px 0 5px 0;
 }

 .tip-message {
  font-weight: 800;
  color: #fff;
  text-align: center;
  margin: 10px;
}

.tip-text {
  color: #545764;
  text-align: center;
  margin: 10px;
}

 .tutorial-container {
  width: 250px;
  
 }

 
 .tutorial, .tutorial2 {
  display: flex;
  margin: 20px 0 10px 0;
  border-radius: 5px;
  background-color: #111111;
 }

 .tutorial2 {
  background-color: #181818;
 }

 .tutorial-icon, .tutorial-icon2 {
  border-radius: 5px;
  padding: 5px 7px 5px 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  background-color: #00000000;
  border: 1px solid #545764;
  color: white;
  margin: 5px;
  margin-right: 20px;
 }
 .tutorial-icon2 {
  border: 0px solid #181818;
 }
 .create-icon {
  background-color: #fff;
  border: 1px solid #fff;
 }

 .tutorial-text {
  display: flex;
 }
 .tutorial-text {
    margin-right: 5px;
 }



 .tutorial-img-container {
    width: 220px;
    border-radius: 15px;
    overflow: hidden;
    margin-left: auto;
    margin-right: auto;
    position: relative;
 }

.tutorial-image-count {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 20px;
  height: 1px;
  background-color: rgba(0, 0, 0, 0.8);
  padding: 9px 2px 9px 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 50px;
  margin: 5px;
  z-index: 1;
  font-size: 10px !important;
  font-weight: 100;
  opacity: 0.8;
}


  .arnold-message-secondary{
    color: #545764;
  }
  .green{
    color: #545764;
    border: 4px solid #5de07a;
    padding: 5px 10px 5px 10px;
    border-radius: 5px;
    background-color: #5de07a;
    opacity: 0.6;
  }
  .black{
    color: #545764;
    border: 1px solid grey;
    padding: 10px;
    border-radius: 5px;
    opacity: 0.6;

  }
  .learn-icon{
    width:15px;
    opacity: 0.6;
  }


  .button3 {
    border-radius: 5px;
    padding: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    background-color: #212121;
    color: white;
    margin: 5px;
  }

  .button-icon2 {
    opacity: 0;
  }

  .user-streak {
    color: white;
    font-size: 16px;
    font-weight: 600;
    width: 40px;
    text-align: center;
    position: absolute;
    margin-bottom: 18px;
  }



  .button-icon3 {
    width: 18px;
    height: 18px;
  }

  .button4 {
    position: absolute;
    right: 15px;
    top: 27px;
    z-index: 10001;
  }

  .button4 {
    padding: 4px 4px 0 4px;
    border-radius: 50px;
    margin-bottom: 5px;
    border: 0px solid #111111;
    /* transition: border 0.5s ease-in-out; */
  }
  
  
  .streak-container {
    width: 250px;
    height: 8px;
    margin: 8px auto;
    border-radius: 3px;
    overflow: hidden;
  }

  .streak-bar {
      background-color: #212121;
      height: 100%;
      width: 100%;
  }

  .streak-container {
    width: 250px;
    height: 8px;
    margin: 8px auto;
    border-radius: 3px;
    overflow: hidden;
  }
  
  .streak-bar {
    background-color: #212121;
    height: 100%;
    width: 100%;
  }
  
 
  .streak-bar-progress {
    height: 100%;
    background: linear-gradient(to right, red, orange, yellow);
    animation: rotateColors 10s linear infinite;
  }

  @keyframes rotateColors {
    0% {
      filter: hue-rotate(240deg);
    }
    50% {
      filter: hue-rotate(310deg);
    }
    100% {
      filter: hue-rotate(240deg);
    }
  }
