

.like-modal {
    border: 1px solid red;
    /* position: absolute;
    margin: 0;
    padding: 1px 3px;
    margin-top: -10px;
    margin-left: 35px;
    border-radius: 10px;
    opacity: 0.8;
    font-size: 10px;
    background-color: #111111;
    border-radius: 50px;
    opacity: 0.6; */
}

.emoji-container {
    display: flex;
}

.emoji {
    /* margin: 4px 5px;
    opacity: 1 ; */
    list-style: none;
    margin-left: 8px;
}